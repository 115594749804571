import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc17a",
  "Dec 1, 2024",
  `
- General
  - Improvements / Fixes
    - Added settings to disable showing cast bars for animation times (called uninterruptible spells).
      - This can be toggled for each nameplate/titan frame individually.
    - Minor tooltip fixes.
    - Fixed some rare errors.
`
);

export default Notes;

%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: ShadowStrike
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: e846e2e0f891e534191da75cd0487529, type: 3}
  beforeChannelSoundDelay: 100
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 432
  castDelay: 380
  animationSpeed: 1.5
  animationDuration: 0.75
  skipCooldownOnChannel: 0
  spellName: Shadow Strike
  description: "Deal <b>4</b> damage to the target, gain one combo point. \n<b>If
    cast again within 3 seconds</b> of the first cast, deal <b>10</b> damage and
    gain <b>2</b> combo points. \n<b>If cast in Shadow Crawl</b>, teleport behind
    your target and deal <b>28</b> damage.\n\nIn addition, slows the target by <b>60%</b>
    for <b>4</b> seconds and reduces the healing taken by the target by <b>25%</b>
    for <b>4</b> seconds."
  icon: {fileID: 2800000, guid: b82ed707bccd0614098ad4bb330394c7, type: 3}
  usableBy: 010000000a000000
  cooldown: 0
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}

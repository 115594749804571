%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: DevastatingStrikeLongCD
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 8300000, guid: d44b46a27bd0a8f4e83641e36dffdc4b, type: 3}
  beforeChannelSoundDelay: 100
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 38
  castDelay: 500
  animationSpeed: 1.5
  animationDuration: 1
  skipCooldownOnChannel: 0
  spellName: Devastating Strike
  description: Deal <b>32</b> damage to the target. Apply <b>Devastating Strike</b>
    to the opponent for <b>2</b> seconds. Reduce all healing received by the target
    by <b>25%</b> for <b>6</b> seconds.
  icon: {fileID: 2800000, guid: 6f7f9289900650948bef4415d8d91de9, type: 3}
  usableBy: 030000000b000000
  cooldown: 8
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: 12d2ef2f459217549b413b4b15bf4b0e, type: 2}

%YAML 1.1
%TAG !u! tag:unity3d.com,2011:
--- !u!114 &11400000
MonoBehaviour:
  m_ObjectHideFlags: 0
  m_CorrespondingSourceObject: {fileID: 0}
  m_PrefabInstance: {fileID: 0}
  m_PrefabAsset: {fileID: 0}
  m_GameObject: {fileID: 0}
  m_Enabled: 1
  m_EditorHideFlags: 0
  m_Script: {fileID: 11500000, guid: f1565e2ac6d6cbe4bb0b302c1b554d10, type: 3}
  m_Name: SwiftJusticeNoCD
  m_EditorClassIdentifier: 
  impactPrefab: {fileID: 0}
  handGlowPrefab: {fileID: 0}
  handGlowScale: 0.2
  castAuraPrefab: {fileID: 0}
  castAuraScale: 0.15
  castAuraYOffset: 0.3
  attachCastAuraToCaster: 0
  playOnCast: []
  castSound: {fileID: 0}
  castSoundVolume: 0.2
  soundBeforeChannel: {fileID: 0}
  beforeChannelSoundDelay: 0
  soundAfterChannel: {fileID: 0}
  afterChannelSoundDelay: 0
  animation: 0
  animationVariation: 2
  castDelay: 180
  animationSpeed: 1
  animationDuration: 1
  skipCooldownOnChannel: 1
  spellName: Swift Justice
  description: "Charge rapidly at the target. \n\n<b>Enemy Target:</b> deal <b>12</b>
    damage and <b>Stun</b> them for <b>1.5</b> seconds, and <b>Dismount</b> them.\n\n<b>Friendly
    Target:</b> reduce all physical damage they take by <b>20%</b> for 4 seconds."
  icon: {fileID: 2800000, guid: 171ada2280fc2a04fbdb7d89a5ac6e30, type: 3}
  usableBy: 
  cooldown: 0
  castTime: 0
  isCastInterruptible: 1
  channelTime: 0
  isOnGlobalCooldown: 1
  aoeRadius: 0
  isHidden: 0
  isInternal: 0
  isRegional: 0
  preventManualCasts: 0
  useKeyboardForFacing: 0
  spellType: {fileID: 11400000, guid: cac5c20beb8c65d47b88d3b03bc2946d, type: 2}

import generateNotes from "./generate";

const Notes = generateNotes(
  "1.0.0rc17",
  "Nov 18, 2024",
  `
- General
  - Improvements
    - Instant cast spells with animation times now show a cast bar (with a different, yellow color).
      - This is something I want to try - it adds a lot of clarity to the game and I think it fixes the feeling of "unresponsiveness" as you can see a clear indicator of what's going on.
      - Will create a thread on this to gather feedback and adjust as necessary.
    - You can now drag your mouse over top of Titan Frames and Nameplates to drag the camera without it blocking the drag.
    - Added a setting to prevent the chat window from fading out.
    - Made some slight adjustments to the matchmaking algorithm.
      - Including some artificial inflation. This will replace top50 decay for now, and we'll see how it is working.
    - Airion now gets their main form back after respawning in CTF.
    - Fixed some more tooltip incorrectness issues on various spells.
- Balance
  - General
    - Absorption now absorbs 40 damage (down from 50).
  - Naturalist
    - Cultivation now heals 8 per stack on expiration (down from 9 per stack).
  - Assassin / Bruiser
    - Shadow Strike base damage has been increased to 4 / 10 alternating (up from 3 / 9).
  - Soulstealer
    - Essence Burst has been reduced to a 1.4 second cast (down from 1.5 sec).
    - Fixed some issues with Soul Swap - it should now function as expected.
  - Fighter
    - The passive "Shattered Armor" now increases the physical damage taken by the target by 0.4% per stack and stacks to 10 (changed from 1% per stack stacking to 5).
    - Swift Justice now triggers the cooldown when used on friendly targets.
  - Blademaster
    - The "Overpowered" talent has been changed to increase the cooldown of Devastating Strike by 1 sec (changed from increasing the CD of Power Strike by 5 sec).
`
);

export default Notes;

import { PageLayout } from "./PageLayout";

import note100rc7 from "./patchnotes/100rc7.js";
import note100rc8 from "./patchnotes/100rc8.js";
import note100rc9 from "./patchnotes/100rc9.js";
import note100rc10 from "./patchnotes/100rc10.js";
import note100rc11 from "./patchnotes/100rc11.js";
import note100rc12 from "./patchnotes/100rc12.js";
import note100rc13 from "./patchnotes/100rc13.js";
import note100rc14 from "./patchnotes/100rc14.js";
import note100rc15 from "./patchnotes/100rc15.js";
import note100rc16 from "./patchnotes/100rc16.js";
import note100rc17 from "./patchnotes/100rc17.js";
import note100rc17a from "./patchnotes/100rc17a.js";
import note1 from "./patchnotes/1.js";
import { Link } from "react-router-dom";

export const patchNotes = [
  { key: "100rc17a", val: note100rc17a },
  { key: "100rc17", val: note100rc17 },
  { key: "100rc16", val: note100rc16 },
  { key: "100rc15", val: note100rc15 },
  { key: "100rc14", val: note100rc14 },
  { key: "100rc13", val: note100rc13 },
  { key: "100rc12", val: note100rc12 },
  { key: "100rc11", val: note100rc11 },
  { key: "100rc10", val: note100rc10 },
  { key: "100rc9", val: note100rc9 },
  { key: "100rc8", val: note100rc8 },
  { key: "100rc7", val: note100rc7 },
  { key: 1, val: note1 },
];

function PatchNotesSection({ notes, depth }) {
  depth = depth ?? 0;

  const content = notes.label ?? notes;

  if (depth === 0) {
    return (
      <>
        <h2 style={{ fontSize: 34, textTransform: "uppercase", margin: 0 }}>
          {content}
        </h2>
        {notes.date}
        {notes.subtitle}
        {notes.children?.map((child) => (
          <PatchNotesSection notes={child} depth={depth + 1} />
        ))}
        {notes.after}
      </>
    );
  }

  if (depth === 1) {
    return (
      <>
        <h3 style={{ fontSize: 24, textTransform: "uppercase" }}>{content}</h3>
        {notes.subtitle}
        {notes.children?.map((child) => (
          <PatchNotesSection notes={child} depth={depth + 1} />
        ))}
        {notes.after}
      </>
    );
  }

  if (depth === 2) {
    return (
      <>
        <h4 style={{ textTransform: "uppercase" }}>{content}</h4>
        {notes.subtitle}
        <ul>
          {notes.children?.map((child) => (
            <PatchNotesSection notes={child} depth={depth + 1} />
          ))}
        </ul>
        {notes.after}
      </>
    );
  }

  return (
    <>
      <li>{content}</li>
      {notes.subtitle}
      <ul>
        {notes.children?.map((child) => (
          <PatchNotesSection notes={child} depth={depth + 1} />
        ))}
      </ul>
      {notes.after}
    </>
  );
}

export default function PatchNotes(props) {
  console.log(props);
  if (props.notes) {
    return (
      <PageLayout label="Patch Notes">
        <div
          style={{
            fontFamily: '"Montserrat", sans-serif',
          }}
        >
          <PatchNotesSection notes={props.notes} />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout label="Patch Notes">
      <h1>PATCH NOTES</h1>
      <p
        style={{
          fontFamily: '"Montserrat", sans-serif',
        }}
      >
        Here you can find all of the recent patch notes for Ring of Titans.
      </p>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        {patchNotes.map((x) => {
          return (
            <div style={{ textTransform: "uppercase", fontSize: 24 }}>
              <Link to={"/patchnotes/" + x.key}>{x.val.label}</Link>
              <div style={{ fontSize: 14 }}>{x.val.date}</div>
            </div>
          );
        })}
      </div>
    </PageLayout>
  );
}
